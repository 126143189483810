import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { Icon, Container, Divider, Form, Header, Button, Message, Table, Popup, Pagination } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
import './main.css'


class App extends React.Component {
    constructor(props) {
        super(props);
        this.venues = [
            ['STOC', 'FOCS', 'SODA'],
            ['AAAI', 'IJCAI'],
            ['NeurIPS', 'ICML', 'ICLR', 'AISTATS'],
            ['KDD', 'ICDM', 'CIKM', 'SDM'],
            ['WWW', 'WSDM', 'SIGIR', 'RecSys']
        ]
        const venue_states = [];
        for(let i = 0; i < this.venues.length; i++){
            const venue_state_row = [];
            for(let j = 0; j < this.venues[i].length; j++){
                venue_state_row.push(this.venues[i][j] === 'NeurIPS' || this.venues[i][j] === 'ICML');
            }
            venue_states.push(venue_state_row);
        }
        this.state = {
            venue_states: venue_states,
            order: 0,
            count_from: '',
            count_to: '',
            year_from: '',
            year_to: '',
            content: '',
            query_update: false,
        };
        
    }

    handleVenueClick(row, col) {
        const venue_states_prev = this.state.venue_states;
        const venue_states = [];
        for(let i = 0; i < this.venues.length; i++){
            const venue_state_row = [];
            for(let j = 0; j < this.venues[i].length; j++){
                if(i === row && j === col){
                    venue_state_row.push(!venue_states_prev[i][j]);
                } else {
                    venue_state_row.push(venue_states_prev[i][j]);
                }
            }
            venue_states.push(venue_state_row);
        }
        this.setState({
            venue_states: venue_states
        });
    }

    handleOrderClick(i) {
        this.setState({
            order: i
        });
    }

    query(page, query_update) {
        const order = this.state.order;
        const venue_states = this.state.venue_states;
        const count_from = this.state.count_from ? this.state.count_from : -1;
        const count_to = this.state.count_to ? this.state.count_to : -1;
        const year_from = this.state.year_from ? this.state.year_from : -1;
        const year_to = this.state.year_to ? this.state.year_to : -1;
        const venues = [];
        for(let i = 0; i < this.venues.length; i++){
            for(let j = 0; j < this.venues[i].length; j++){
                if(venue_states[i][j]){
                    venues.push(this.venues[i][j]);
                }
            }
        }
        let query = {
            venues: venues,
            order: order,
            count_from: count_from,
            count_to: count_to,
            year_from: year_from,
            year_to: year_to,
            page: page
        };
        axios.post(process.env.REACT_APP_PSEARCH_BACKEND_URL, query)
        .then(response => {
            this.setState({
                content: {
                    page: response.data.page,
                    total_pages: response.data.total_pages,
                    data: response.data.data,
                },
                query_update: query_update,
            })
        }).catch(err => {
            this.setState({
                content: 'Error'
            })
        })
    }

    handleSubmit() {
        this.query(1, false);
    }

    componentDidUpdate(){
        if(this.state.query_update){
            window.scrollTo(0, 0);
            this.setState({
                query_update: false,
            })
        }
    }

    render() {
        const content = this.state.content;
        const order = this.state.order;
        const venue_states = this.state.venue_states;
        const venues = [];
        for(let i = 0; i < this.venues.length; i++){
            const venue_row = [];
            for(let j = 0; j < this.venues[i].length; j++){
                venue_row.push(
                    <Button type='button' toggle key={j} active={venue_states[i][j]} onClick={() => this.handleVenueClick(i, j)} style={{'marginBottom': '0.5em'}}>{this.venues[i][j]}</Button>
                );
            }
            venues.push(
                <div key={i} widths='equal'>{venue_row}</div>
            );
        }
        const content_component = [];
        if(content === ''){

        } else if(content === 'Error'){
            content_component.push(
                <Message negative key='0'>
                    <p>Error</p>
                </Message>
            );
        } else if(content.total_pages === 0){
            content_component.push(
                <Message warning key='0'>
                    <p>Paper Not Found</p>
                </Message>
            );
        } else if(content !== ''){
            const table_body = [];
            for(let row of content.data){
                table_body.push(
                    <Table.Row key={row[0]}>
                        <Table.Cell>
                            <Popup
                                trigger={
                                    <Icon name='copy outline' onClick={() => {navigator.clipboard.writeText(row[0])}} className='clipboard' />
                                }
                                content='Copied!'
                                on='click'
                                position='top center'
                            />
                            {row[0]}
                        </Table.Cell>
                        <Table.Cell>{row[2]}</Table.Cell>
                        <Table.Cell>{row[1]}</Table.Cell>
                        <Table.Cell>{row[3]}</Table.Cell>
                    </Table.Row>                    
                )
            }
            content_component.push(
                <Table celled key='0'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.HeaderCell>Venue</Table.HeaderCell>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            <Table.HeaderCell>Cited by</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {table_body}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="3">
                                <Pagination
                                    floated='right'
                                    activePage={content.page}
                                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                    totalPages={content.total_pages}
                                    onPageChange={(e, {activePage}) => {this.query(activePage, true)}}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            )
        }
        return (
            <Container>
                <Divider hidden />
                <Header as='h1' dividing>
                    <Icon name='book' />
                    <Header.Content>Paper Search from Venues</Header.Content>
                </Header>

                <Form onSubmit={() => {this.handleSubmit()}}>
                    {venues}
                    <Form.Group widths='equal'>
                        <label>Year (inclusive)</label>
                        <Form.Input fluid label='from' placeholder='2020' onChange={event => {this.setState({year_from: event.currentTarget.value});}} />
                        <Form.Input fluid label='to'   placeholder='2020' onChange={event => {this.setState({year_to: event.currentTarget.value});}} /> 
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <label>Citation Count (inclusive)</label>
                        <Form.Input fluid label='from' placeholder='100' onChange={event => {this.setState({count_from: event.currentTarget.value});}} />
                        <Form.Input fluid label='to'   placeholder=''    onChange={event => {this.setState({count_to: event.currentTarget.value});}} /> 
                    </Form.Group>
                    <Form.Group>
                        <label>Order by</label>
                        <Form.Button type='button' toggle active={order === 0} onClick={() => this.handleOrderClick(0)} style={{'marginBottom': '0.5em'}}>Citation (decreasing)</Form.Button>
                        <Form.Button type='button' toggle active={order === 1} onClick={() => this.handleOrderClick(1)} style={{'marginBottom': '0.5em'}}>Citation (increasing)</Form.Button>
                        <Form.Button type='button' toggle active={order === 2} onClick={() => this.handleOrderClick(2)} style={{'marginBottom': '0.5em'}}>Year (decreasing)</Form.Button>
                        <Form.Button type='button' toggle active={order === 3} onClick={() => this.handleOrderClick(3)} style={{'marginBottom': '0.5em'}}>Year (increasing)</Form.Button>
                    </Form.Group>
                    <Form.Group>
                        <Form.Button primary id="hoge">Search</Form.Button>
                    </Form.Group>
                </Form>
                {content_component}
                <Message>
                    <p>
                        このサービスは <a href='https://api.semanticscholar.org/corpus' target='_blank' rel='noopener noreferrer'>Semantic Scholar Open Research Corpus</a> を <a href='https://opendatacommons.org/licenses/by/1.0/' target='_blank' rel='noopener noreferrer'>ODC-BY</a> ライセンスのもと使用しています。
                    </p>
                    <p>
                        This service uses <a href='https://api.semanticscholar.org/corpus' target='_blank' rel='noopener noreferrer'>Semantic Scholar Open Research Corpus</a> under the <a href='https://opendatacommons.org/licenses/by/1.0/' target='_blank' rel='noopener noreferrer'>ODC-BY</a> license.
                    </p>
                    <i>Waleed Ammar et al. <a href='https://www.semanticscholar.org/paper/09e3cf5704bcb16e6657f6ceed70e93373a54618' target='_blank' rel='noopener noreferrer'>Construction of the Literature Graph in Semantic Scholar</a>. NAACL 2018.</i>
                </Message>
            </Container>
        )
    }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
)